<template>
  <div>
    <input type="file" @change="onFileChange" @input="handleInput($event)" />
    <div id="preview">
      <img v-if="previewUrl" :src="previewUrl" :width="width" :height="height" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageInput',
  props: {
    width: {
      type: Number,
      default: null
    },
    height: {
      type: Number,
      default: null
    },
    value: {
      type: [Object, File]
    }
  },
  data() {
    return {
      previewUrl: null,
      currentValue: null,
      selectedFile: null
    };
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue;
      if (newValue && newValue.content) {
        const file = this.b64toFile(newValue.content, newValue.fileName, newValue.mediaType);
        this.previewUrl = URL.createObjectURL(file);
      }
    }
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0];
      this.previewUrl = URL.createObjectURL(file);
    },
    handleInput(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      this.selectedFile = file;
      reader.addEventListener('load', this.readFile);
      reader.readAsBinaryString(file);
    },
    readFile(event) {
      console.log(this.selectedFile);
      const appFile = {
        mediaType: this.selectedFile.type,
        fileName: this.selectedFile.name,
        content: btoa(event.target.result)
      };
      this.$emit('input', appFile);
    },
    b64toFile(b64Data, fileName = '', contentType = '', sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      return new File(byteArrays, fileName, { type: contentType });
    }
  }
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: right;
  align-items: center;
}

#preview img {
  max-width: 100%;
}
</style>
